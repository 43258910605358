import React from "react";
import ButtonTestModal from "../components/ButtonTestModal";
import Card from "../components/Card";
import InformationContent from "../components/InformationContent";
import Layout from "../components/layout";
import SEO from "../components/seo";

function Planos() {
  // const [switchState, setSwitchState] = useState("monthly");

  return (
    <Layout>
      <SEO
        keywords={[
          `Biver`,
          `CRM`,
          `Franquias`,
          `Vendedores`,
          `Vendas`,
          `Gestão`,
        ]}
        title="Planos e preços"
      />
      <h1 className="text-center font-bold mx-5 xl:mx-64 my-24">
        Conheça nossos planos e veja qual se encaixa melhor ao seu negócio
      </h1>

      {/* <Switch
        className="mb-4"
        switchState={switchState}
        setSwitchState={setSwitchState}
      />
     */}
      <div className="flex grid md:grid-cols-2 xl:grid-cols-4 gap-4 mx-5 md:mx-16 lg:mx-40 xl:mx-0 lg:my-12">
        <Card
          className="col-span-1 xl:col-start-2"
          icon="award"
          title="CRM"
          description="Ideal para empresas que desejam aumentar o faturamento de sua equipe de vendas."
        >
          {/* <p className="text-gray">A partir de</p>
          <div className="flex items-baseline">
            <p className="text-blue">R$</p>
            <h1 className="text-blue font-bold">
              {switchState === "monthly" ? " 49,90 " : " 43,91 "}
            </h1>
            <p className="text-blue">/mês</p>
          </div> 
          <p className="text-gray mb-8">1 usuário</p> */}
          <ButtonTestModal>ENTRAR EM CONTATO</ButtonTestModal>
          <h3 className="mt-8 mb-3 font-bold">Principais recursos: </h3>
          <div className="mb-3">
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Funil de vendas</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Gestão de tarefas</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Gestão de propostas</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Cadastro de produtos e serviços</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Histórico de interação</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Envio de e-mails</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Relatórios</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Agenda</p>
            </div>
            <div className="flex">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Proposta comercial</p>
            </div>
          </div>
        </Card>
        <Card
          className="col-span-1 xl:col-start-3"
          bestSeller
          icon="trophy"
          title="CRM + Gestão de franquias"
          description="Solução voltada para rede de franqueados, com o objetivo de padronizar a gestão, provocando melhorias no processo de comunicação e de vendas."
        >
          {/* <p className="text-gray">A partir de</p>
          <div className="flex items-baseline">
            <p className="text-blue">R$</p>
            <h1 className="text-blue font-bold">
              {switchState === "monthly" ? " 109,80 " : " 96,62 "}
            </h1>
            <p className="text-blue">/mês</p>
          </div>
          <p className="text-gray mb-8">1 usuário</p> */}
          <ButtonTestModal>ENTRAR EM CONTATO</ButtonTestModal>
          <h3 className="mt-8 mb-3 font-bold">Principais recursos: </h3>
          <div className="mb-3">
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Funil de vendas</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Gestão de tarefas</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Gestão de propostas</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Cadastro de produtos e serviços</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Histórico de interação</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Envio de e-mails</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Relatórios</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Agenda</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Proposta comercial</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Gestão de franquias</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Registro de chamados</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Envio de comunicados</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Biblioteca de materiais</p>
            </div>
            <div className="flex">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Gestão de royalties</p>
            </div>
          </div>
        </Card>
      </div>

      <div className="my-10 pb-8 text-center mx-10">
        <small className="text-gray">
          ¹ Implantação e treinamentos são serviços opcionais com custo a parte.
        </small>
        <br />
        <small className="text-gray">
          ² No plano CRM + Gestão de franquias, cada usuário por unidade
          franqueada tem um valor adicional.
        </small>
      </div>

      <div className="background-white py-24">
        <h1 className="text-center font-bold mx-5 lg:mx-64">
          Perguntas frequentes
        </h1>
        <div className="lg:flex lg:mx-24 xl:mx-56">
          <div className="mx-10 lg:w-1/2">
            <h3 className="font-bold mt-8 mb-2">Como o Biver funciona?</h3>
            <p className="text-gray">
              O Biver é um software em nuvem, que pode ser usado pelo navegador.
              Não é necessário instalar nada no computador para poder usá-lo.
              Assim como outros serviços de assinatura, o Biver cobra um valor
              mensal pela quantidade de usuários na conta.
            </p>
            <h3 className="font-bold mt-8 mb-2">
              Quais as formas de pagamento?
            </h3>
            <p className="text-gray">
              Aceitamos boleto e todos os cartões de crédito. No boleto, pode
              levar até 3 dias úteis para que o pagamento seja compensado e a
              conta liberada.
            </p>
            <h3 className="font-bold mt-8 mb-2">
              Vocês emitem nota fiscal sobre a aquisição do Biver?
            </h3>
            <p className="text-gray">
              Sim, logo após a confirmação do pagamento.
            </p>
            {/* <h3 className="font-bold mt-8 mb-2">
              Como funciona a avaliação gratuita?
            </h3>
            <p className="text-gray">
              Nós oferecemos 7 dias gratuitos para você testar todos os
              recursos. Avisaremos quando o teste estiver terminando. Todas as
              suas informações serão mantidas seguras na conta mesmo depois dos
              7 dias, mas basta nos avisar caso queira removê-las.
      </p> */}
          </div>
          <div className="mx-10 lg:w-1/2">
            <h3 className="font-bold mt-8 mb-2">
              Minhas informações estarão seguras no Biver?
            </h3>
            <p className="text-gray">
              Utilizamos os Bancos de Dados da Amazon, que é a mesma plataforma
              que os bancos utilizam. É considerada uma das plataformas mais
              seguras da internet, utilizada pelas principais empresas do mundo.
            </p>
            <h3 className="font-bold mt-8 mb-2">
              Não tenho tempo para implementar, o que eu faço?
            </h3>
            <p className="text-gray">
              Caso seja necessário, possuímos uma equipe para te auxiliar nessa
              etapa. Vamos garantir as configurações necessárias para o início
              do uso do CRM, além de ensinar as melhores práticas de uso do
              sistema.
            </p>
          </div>
        </div>
      </div>

      <InformationContent
        className="mx-5 xl:mx-64 my-32"
        title="Junte-se as diversas empresas que confiam no Biver"
        description="Faça parte das empresas que estão crescendo com o CRM e veja suas vendas aumentarem rapidamente."
      />
    </Layout>
  );
}

export default Planos;
